<template>
  <div class="page">
    <base-left-nav-bar :activeIndex="activeIndex"></base-left-nav-bar>
    <div class="main">
      <base-top-nav-bar></base-top-nav-bar>
      <div class="mian-wrapper">
        <base-page-header>
          <span slot="r"
            ><slot><a href="/companyList">公司管理 — </a></slot></span
          >
          <span slot="m"
            ><slot><a href="/companyList">客户公司列表 </a></slot></span
          >
          <span slot="l"><slot>> 修改信息 </slot></span>
        </base-page-header>
        <div class="main-manager">
          <el-form
            :model="companyForm"
            :rules="rules"
            ref="companyForm"
            label-width="150px"
            class="companyForm"
          >
            <el-form-item label="序号：">
              <p>{{ companyForm.companyid }}</p>
            </el-form-item>
            <el-form-item label="公司全名：" prop="name">
              <el-input
                v-model="companyForm.name"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司简称：" prop="nickname">
              <el-input
                v-model="companyForm.nickname"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司详细地址：" prop="address">
              <el-input
                v-model="companyForm.address"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="公司负责人：" prop="manager_info">
              <el-input
                v-model="companyForm.manager_info"
                placeholder="请输入"
                @change="userGet"
              ></el-input>
              <span class="tips">注：输入手机号验证人名</span>
            </el-form-item> -->
            <el-form-item label="创建时间：">
              <p>{{ companyForm.date }}</p>
            </el-form-item>
            <el-form-item>
              <el-button class="reset" @click="resetForm">重置</el-button>
              <el-button
                class="submit"
                type="primary"
                @click="submitForm('companyForm')"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLeftNavBar from 'components/base-nav-bar/base-left-nav-bar.vue'
import BaseTopNavBar from 'components/base-nav-bar/base-top-nav-bar.vue'
import BasePageHeader from '../../components/base-page-header/base-page-header.vue'

import CompanyJS from 'network/company/company.js'

export default {
  components: {
    BaseLeftNavBar,
    BaseTopNavBar,
    BasePageHeader
  },
  data() {
    return {
      activeIndex: 1,
      params: {},
      companyForm: {
        name: '',
        nickname: '',
        address: ''
      },
      rules: {
        name: [{ required: true, message: '请输入公司全名', trigger: 'blur' }],
        nickname: [
          { required: true, message: '请输入公司简称', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入公司详细地址', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {},
  methods: {
    /**
     * 事件监听
     */
    // 修改并提交用户表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        this.userUpdate()
      })
    },
    resetForm() {
      this.companyGet()
    },
    /**
     * 网络请求
     */
    userUpdate() {
      this.companyForm.session_id = this.params.session_id
      CompanyJS.companyUpdate(this.companyForm).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.$router.replace('/companyList')
          }, 500)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    companyGet() {
      this.params.session_id = this.$store.state.loginInfo.session_id
      this.params.companyid = this.$route.query.companyid
      CompanyJS.companyGet(this.params).then((res) => {
        if (res.code == 0) {
          this.companyForm = res.company_data
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    }
  },
  mounted() {
    this.companyGet()
  },
  created() {
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
  },
  beforeDestroy() {
    this.$message.closeAll()
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-width: 1300px;
  min-height: 100vh;
  // min-height: 1080px;
}
.main {
  width: 1698px;
  height: auto;
  background: #ebecee;
}
.mian-wrapper {
  padding: 28px; // 边距
  width: 1698px;
  height: 879px;
  .main-manager {
    width: 1642px;
    height: 82.5vh;
    background: #fff;
    .companyForm {
      padding: 85px 0 0 72px;
      width: auto;
      height: auto;
      .tips {
        margin-left: 17px;
        width: auto;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(1, 158, 229, 0.88);
      }
      .reset {
        margin-left: 90px;
        border-radius: 0;
      }
      .submit {
        margin-left: 20px;
        border-radius: 0;
      }
    }
  }

  ::v-deep {
    .el-input__inner {
      border-radius: 0px;
    }
    .el-form-item:not(:last-child) {
      margin-bottom: 28px;
      border-radius: 0px;
      .el-form-item__label {
        font-size: 18px;
        font-family: Source Han Sans CN;
      }
      .el-input {
        width: 332px;
        height: 42px;
      }
    }
  }
}
</style>
